<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <v-icon>mdi-account-details</v-icon>
      Aylık BB-Sakin Analiz
    </v-card-title>
    <v-card-text v-if="clusterId">
      <v-row dense>
        <v-col>
          <v-form
            ref="form"
            @submit.prevent="handleFormSubmit"
            class="mb-3"
            :disabled="isLoading"
          >
            <v-row cols="12" dense>
              <v-col sm="3" lg="2">
                <rs-datepicker
                  label="Tahakkuk Başlangıç Tarih"
                  v-model="search.issues_on.min"
                  :rules="[
                    rules.maxDate(search.issues_on.min, search.issues_on.max),
                  ]"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-datepicker
                  label="Tahakkuk Bitiş Tarihi"
                  v-model="search.issues_on.max"
                  :rules="[
                    rules.minDate(search.issues_on.max, search.issues_on.min),
                  ]"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-datepicker
                  label="Son Ödeme Başlangıç Tarih"
                  v-model="search.last_payment_on.min"
                  :rules="[
                    rules.maxDate(
                      search.last_payment_on.min,
                      search.last_payment_on.max
                    ),
                  ]"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-datepicker
                  label="Son Ödeme Bitiş Tarihi"
                  v-model="search.last_payment_on.max"
                  :rules="[
                    rules.minDate(
                      search.last_payment_on.max,
                      search.last_payment_on.min
                    ),
                  ]"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-select
                  label="Borçlandırma Tipi"
                  :items="incomeTypeList"
                  v-model="search.income_type_id"
                  multiple
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-select
                  label="İcradaki Borçlar"
                  :items="yesNoOptions"
                  v-model="search.has_enforcement"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-select
                  label="Oturum Şekli"
                  :items="houseUserTypeList"
                  v-model="search.house_user_type_id"
                  multiple
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-select
                  label="Blok"
                  :items="blocks"
                  v-model="search.block_id"
                  multiple
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-select-project v-model="search.project_id" multiple />
              </v-col>
              <v-col cols="12">
                <rs-select
                  label="Ek Sütunlar"
                  v-model="search.selected_extra_rows"
                  :items="extraRowList"
                  multiple
                />
              </v-col>
              <v-col>
                <rs-form-buttons
                  :is-loading="isLoading"
                  @submit="handleFormSubmit"
                  hide-cancel
                  hide-save-close
                  saveLabel="Filtrele"
                />
              </v-col>
              <v-col
                sm="12"
                class="d-flex align-items-center justify-content-end mt-3"
              >
                <v-btn small color="pink" outlined @click="exportXLSX()">
                  <v-icon>mdi-file-table</v-icon>
                  Dışa Aktar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-simple-table dense id="printTable">
        <template v-slot:default>
          <thead>
            <tr class="bg-secondary bordered">
              <th class="text-left bg-secondary" rowspan="3">Blok</th>
              <th class="text-left bg-secondary" rowspan="3">BB No</th>
              <th class="text-left bg-secondary" rowspan="3">Sakin</th>
              <th class="text-left bg-secondary" rowspan="3">Oturum</th>
              <th
                rowspan="3"
                v-if="
                  search.selected_extra_rows.indexOf('future_owner_name') !== -1
                "
              >
                Sözleşmeli Ev Sahibi
              </th>
              <th
                rowspan="3"
                v-if="
                  search.selected_extra_rows.indexOf(
                    'future_owner_delivery_on'
                  ) !== -1
                "
              >
                Teslim Tarihi
              </th>
              <th
                rowspan="3"
                v-if="
                  search.selected_extra_rows.indexOf(
                    'future_owner_is_conditional'
                  ) !== -1
                "
              >
                Şartlı Teslim
              </th>
              <th
                rowspan="3"
                v-if="
                  search.selected_extra_rows.indexOf(
                    'future_owner_description'
                  ) !== -1
                "
              >
                Teslim Açıklaması
              </th>
              <th
                v-for="year in yearList"
                :key="year"
                :colspan="
                  monthRowList.filter((h) => h.year === year).length * 3
                "
              >
                {{ year }}
              </th>
              <th colspan="3" rowspan="2">Toplam</th>
            </tr>
            <tr class="bg-secondary bordered">
              <th
                class="text-center"
                v-for="cell in monthRowList"
                :key="cell.key"
                colspan="3"
              >
                <div>
                  {{ cell.monthName }}
                </div>
              </th>
            </tr>
            <tr class="bg-secondary bordered">
              <template v-for="cell in monthRowList">
                <th
                  class="text-left bg-secondary text-center"
                  :key="cell.key + 'Tahakkuk'"
                >
                  Tahakkuk
                </th>
                <th class="text-left bg-secondary" :key="cell.key + 'Ödenen'">
                  Ödenen
                </th>
                <th class="text-left bg-secondary" :key="cell.key + 'Kalan'">
                  Kalan
                </th>
              </template>
              <th class="text-left bg-secondary p-0 text-center">Tahakkuk</th>
              <th class="text-left bg-secondary">Ödenen</th>
              <th class="text-left bg-secondary">Kalan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in list" :key="row.id" class="bordered">
              <!-- <tr v-for="row in list" :key="row.id" class="bordered"> -->
              <td
                v-if="
                  (index === 0 ||
                    list[index - 1].block_name !== row.block_name) &&
                  !print
                "
                :rowspan="
                  list.filter((v) => v.block_id === row.block_id).length
                "
              >
                {{ row.block_name }}
              </td>
              <td v-else-if="print">{{ row.block_name }}</td>
              <td
                v-if="
                  (index === 0 || list[index - 1].house_id !== row.house_id) &&
                  !print
                "
                :rowspan="
                  list.filter((v) => v.house_id === row.house_id).length
                "
              >
                {{ row.door_number }}
              </td>
              <td v-else-if="print">{{ row.door_number }}</td>
              <td>{{ row.resident_name }}</td>
              <td>{{ hut["hut-" + row.house_user_type_id] }}</td>
              <td
                v-if="
                  search.selected_extra_rows.indexOf('future_owner_name') !== -1
                "
              >
                {{ row.future_owner_name }}
              </td>
              <td
                v-if="
                  search.selected_extra_rows.indexOf(
                    'future_owner_delivery_on'
                  ) !== -1
                "
              >
                <rs-table-cell-date :value="row.future_owner_delivery_on" />
              </td>
              <td
                v-if="
                  search.selected_extra_rows.indexOf(
                    'future_owner_is_conditional'
                  ) !== -1
                "
              >
                <rs-table-cell-boolean
                  :value="row.future_owner_is_conditional"
                />
              </td>
              <td
                v-if="
                  search.selected_extra_rows.indexOf(
                    'future_owner_description'
                  ) !== -1
                "
              >
                {{ row.future_owner_description }}
              </td>
              <template v-for="monthYear in monthRowList">
                <td class="text-right" :key="monthYear.key + 'Debt'">
                  <rs-table-cell-number
                    :print="print"
                    price
                    v-if="
                      listObject['hu-' + row.house_user_id].amounts[
                        monthYear.key
                      ]?.debt
                    "
                    :value="
                      listObject['hu-' + row.house_user_id].amounts[
                        monthYear.key
                      ].debt
                    "
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>

                <td class="text-right" :key="monthYear.key + 'Collecting'">
                  <rs-table-cell-number
                    :print="print"
                    price
                    v-if="
                      listObject['hu-' + row.house_user_id].amounts[
                        monthYear.key
                      ]?.collected
                    "
                    :value="
                      listObject['hu-' + row.house_user_id].amounts[
                        monthYear.key
                      ].collected
                    "
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>

                <td class="text-right" :key="monthYear.key + 'Remaining'">
                  <rs-table-cell-number
                    :print="print"
                    price
                    v-if="
                      listObject['hu-' + row.house_user_id].amounts[
                        monthYear.key
                      ]?.remaining
                    "
                    :value="
                      listObject['hu-' + row.house_user_id].amounts[
                        monthYear.key
                      ].remaining
                    "
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>
              </template>
              <td class="text-right green lighten-4">
                <template
                  v-if="
                    houseUserTotalsList[index] &&
                    houseUserTotalsList[index].debt_amount
                  "
                >
                  <rs-table-cell-number
                    :print="print"
                    price
                    :value="houseUserTotalsList[index].debt_amount"
                  />
                </template>
                <em class="text-muted" v-else>
                  <rs-table-cell-number :print="print" price :value="0.0" />
                </em>
              </td>
              <td class="text-right green lighten-4">
                <template
                  v-if="
                    houseUserTotalsList[index] &&
                    houseUserTotalsList[index].debt_collecting_amount
                  "
                >
                  <rs-table-cell-number
                    :print="print"
                    price
                    :value="houseUserTotalsList[index].debt_collecting_amount"
                  />
                </template>
                <em class="text-muted" v-else>
                  <rs-table-cell-number :print="print" price :value="0.0" />
                </em>
              </td>
              <td class="text-right green lighten-4">
                <template
                  v-if="
                    houseUserTotalsList[index] &&
                    houseUserTotalsList[index].remaining
                  "
                >
                  <rs-table-cell-number
                    :print="print"
                    price
                    :value="houseUserTotalsList[index].remaining"
                  />
                </template>
                <em class="text-muted" v-else>
                  <rs-table-cell-number :print="print" price :value="0.0" />
                </em>
              </td>
            </tr>
            <tr v-if="!isLoading && list.length === 0">
              <td colspan="7" class="text-center">
                <em>Filtrenize uygun veri bulunamadı.</em>
              </td>
            </tr>
            <tr v-if="isLoading">
              <td colspan="7" class="text-center">
                <v-progress-linear color="pink" indeterminate />
              </td>
            </tr>
          </tbody>
          <!-- <tfoot>
            <tr class="bg-secondary bordered">
              <th class="text-left" colspan="3">Toplamlar:</th>
              <template v-for="it in totals.income_type_sums">
                <td class="text-right" :key="it.id + 'Kalan'">
                  <rs-table-cell-number :print="print"
                    price
                    v-if="it.remaining_amount"
                    :value="it.remaining_amount"
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>
                <td class="text-right" :key="it.id + 'Gecikme'">
                  <rs-table-cell-number :print="print"
                    price
                    v-if="it.remaining_deferment_amount"
                    :value="it.remaining_deferment_amount"
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>
                <td class="text-right" :key="it.id + 'Bakiye'">
                  <rs-table-cell-number :print="print"
                    price
                    v-if="it.remaining_total_amount"
                    :value="it.remaining_total_amount"
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>
              </template>
              <td class="text-right">
                <rs-table-cell-number :print="print" price :value="totals.collecting_amount" />
              </td>
              <td class="text-right">
                <rs-table-cell-number :print="print" price :value="totals.remaining_amount" />
              </td>
              <td class="text-right">
                <rs-table-cell-number :print="print"
                  price
                  v-if="totals.remaining_deferment_amount"
                  :value="totals.remaining_deferment_amount"
                />
                <em class="text-muted" v-else>
                  <rs-table-cell-number :print="print" price :value="0.0" />
                </em>
              </td>
              <td class="text-right">
                <rs-table-cell-number :print="print"
                  price
                  :value="totals.remaining_total_amount"
                />
              </td>
            </tr>
          </tfoot> -->
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
/** @typedef {{
 * resident_id: number,
 * resident_name: string
 * house_id: number,
 * door_number: string,
 * block_id: number,
 * block_name: string,
 * house_user_id: number,
 * year: number,
 * month: number,
 * debt_amount: number,
 * debt_collecting_amount: number,
 * house_user_type_id: number,
 * future_owner_name: String,
 * future_owner_is_conditional: Boolean,
 * future_owner_delivery_on: string,
 * future_owner_description: string
 * }} ResponseRow
 */

/** @typedef {{
 * year: number,
 * month: number,
 * debt: number,
 * collected: number,
 * remaining: number
}} RowAmount */

/** @typedef {{
 * resident_id: number,
 * resident_name: string
 * house_id: number,
 * door_number: string,
 * block_id: number,
 * block_name: string,
 * house_user_id: number,
 * amounts: RowAmount[],
 * house_user_type_id: number,
 * future_owner_name: String,
 * future_owner_is_conditional: Boolean,
 * future_owner_delivery_on: Date,
 * future_owner_description: string
}} TableRow */

import { mapGetters } from "vuex";

import { filtersToURL, hasExport, hasForm } from "@/view/mixins";
import { utils, writeFile } from "xlsx";

export default {
  name: "IncomeTypeHouseUserList",
  mixins: [filtersToURL, hasExport, hasForm],
  computed: {
    ...mapGetters([
      "blocks",
      "clusterId",
      "houseUserTypeList",
      "incomeTypeList",
      "monthList",
    ]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
  data() {
    return {
      search: {
        issues_on: {
          min: undefined,
          max: undefined,
        },
        last_payment_on: {
          min: undefined,
          max: undefined,
        },
        income_type_id: null,
        project_id: null,
        has_enforcement: null,
        house_user_type_id: null,
        block_id: null,
        selected_extra_rows: [],
      },
      print: false,
      extraRowList: [
        {
          id: "future_owner_name",
          name: "Sözleşmeli Ev Sahibi",
        },
        {
          id: "future_owner_is_conditional",
          name: "Şartlı Teslim",
        },
        {
          id: "future_owner_delivery_on",
          name: "Teslim Tarihi",
        },
        {
          id: "future_owner_description",
          name: "Şartlı Teslim Açıklaması",
        },
      ],
      yearList: [],
      /** @type string[] */
      monthDataList: [],
      monthRowList: [],
      headerList: [],
      hut: {},
      isLoading: false,
      houseUserTotalsList: [],
      list: [],
      listObject: {},
      totals: {},
      yesNoOptions: [
        {
          id: true,
          name: this.$t("yes"),
        },
        {
          id: false,
          name: this.$t("no"),
        },
      ],
    };
  },
  methods: {
    exportXLSX() {
      this.print = true;

      this.$nextTick(() => {
        try {
          const element = document.getElementById("printTable");
          const ws = utils.table_to_sheet(
            element.getElementsByTagName("TABLE")[0]
          );
          const wb = utils.book_new();
          utils.book_append_sheet(wb, ws, "Export");
          this.print = false;
          return writeFile(wb, "Robosay - Aylık BB-Sakin Analiz.xlsx");
        } catch (e) {
          this.handleError(e);
          this.print = false;
        }
      });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        this.list = [];
        return;
      }

      this.setURLParams();
    },
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (params.issues_on?.min === null) {
        params.issues_on.min = undefined;
      }
      if (params.issues_on?.max === null) {
        params.issues_on.max = undefined;
      }
      if (params.last_payment_on?.min === null) {
        params.last_payment_on.min = undefined;
      }
      if (params.last_payment_on?.max === null) {
        params.last_payment_on.max = undefined;
      }

      if (params.phone) {
        params.phone = params.phone.replace(/[^0-9+]/g, "");
      }

      params.cluster_id = this.clusterId;

      params.billed_on = {
        min: this.formData.start_date || undefined,
        max: this.formData.end_date || undefined,
      };

      params.per_page = undefined;

      return params;
    },
    loadList() {
      if (!this.clusterId) {
        return false;
      }

      this.list = [];
      this.totals = {};
      this.houseUserTotalsList = [];
      this.yearList = [];
      this.monthDataList = [];
      this.headerList = [];
      this.monthRowList = [];
      this.listObject = {};

      const params = this.getParams();
      this.isLoading = true;

      this.$api
        .query("income-analysis/monthly-sums-by-house-user/" + this.clusterId, {
          params,
        })
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            const list = [];

            /** @type {ResponseRow[]} */
            const data = response.data.data;

            this.monthDataList = data
              .map((item) => item.year + "-" + item.month)
              .filter((item, index, array) => {
                return array.indexOf(item, index + 1) === -1;
              })
              .sort((a, b) => {
                const splitA = a.split("-");
                const splitB = b.split("-");

                if (splitA[0] < splitB[0]) {
                  return -1;
                } else if (splitA[0] > splitB[0]) {
                  return 1;
                }

                if (parseInt(splitA[1]) < parseInt(splitB[1])) {
                  return -1;
                } else if (parseInt(splitA[1]) > parseInt(splitB[1])) {
                  return 1;
                }

                return 0;
              });

            this.monthDataList.forEach((v) => {
              const row = {};
              row.monthName = this.monthList[parseInt(v.split("-")[1]) - 1];
              row.key = v;
              row.year = parseInt(v.split("-")[0]);

              this.headerList.push(row);
            });

            const houseUserIdList = [];

            for (const index in data) {
              const item = data[index];
              if (houseUserIdList.indexOf(item.house_user_id) === -1) {
                houseUserIdList.push(item.house_user_id);

                /** @type {TableRow} **/
                const row = {
                  house_user_id: item.house_user_id,
                  house_id: item.house_id,
                  resident_id: item.resident_id,
                  block_id: item.block_id,
                  resident_name: item.resident_name,
                  block_name: item.block_name,
                  door_number: item.door_number,
                  house_user_type_id: item.house_user_type_id,
                  future_owner_name: item.future_owner_name,
                  future_owner_is_conditional: item.future_owner_is_conditional,
                  future_owner_description: item.future_owner_description,
                  future_owner_delivery_on: item.future_owner_delivery_on
                    ? new Date(item.future_owner_delivery_on)
                    : null,
                  amounts: [],
                };

                const houseUserData = data.filter(
                  (v) => v.house_user_id === item.house_user_id
                );

                this.listObject["hu-" + row.house_user_id] = Object.assign(
                  {},
                  row
                );
                this.listObject["hu-" + row.house_user_id]["amounts"] = {};

                for (const i2 in houseUserData) {
                  const item2 = houseUserData[i2];

                  row.amounts.push({
                    year: item2.year,
                    month: item2.month,
                    debt: item2.debt_amount,
                    collected: item2.debt_collecting_amount,
                    remaining:
                      (item2.debt_amount * 100 -
                        item2.debt_collecting_amount * 100) /
                      100,
                  });

                  this.listObject["hu-" + row.house_user_id]["amounts"][
                    item2.year + "-" + item2.month
                  ] = {
                    debt: item2.debt_amount,
                    collected: item2.debt_collecting_amount,
                    remaining:
                      (item2.debt_amount * 100 -
                        item2.debt_collecting_amount * 100) /
                      100,
                  };
                }

                list.push(row);
              }
            }

            this.list = list;

            if (response.data.meta && response.data.meta.totals) {
              this.totals = response.data.meta.totals;
              this.houseUserTotalsList =
                response.data.meta.totals.house_user_totals.map((v) => {
                  v.remaining =
                    (v.debt_amount * 100 - v.debt_collecting_amount * 100) /
                    100;

                  return v;
                });

              for (
                let y = this.totals.min_year;
                y <= this.totals.max_year;
                y++
              ) {
                this.yearList.push(y);

                for (let m = 1; m <= 12; m++) {
                  if (y === this.totals.min_year && m < this.totals.min_month) {
                    continue;
                  }

                  if (y === this.totals.max_year && m > this.totals.max_month) {
                    break;
                  }

                  this.monthRowList.push({
                    year: y,
                    month: m,
                    key: y + "-" + m,
                    monthName: this.monthList[m - 1],
                  });
                }
              }
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadURLParams();

    for (const i in this.houseUserTypeList) {
      this.hut["hut-" + this.houseUserTypeList[i].id] =
        this.houseUserTypeList[i].name;
    }
  },
};
</script>

<style>
tr.bordered th,
tr.bordered td,
tr.bordered td.green.lighten-4 {
  border: 1px solid #0000001f !important;
}

tr > td.sticky,
tr > th.sticky {
  border: 1px solid #0000001f !important;
  left: 0;
  position: sticky;
}

tr > td.sticky.sticky-top,
tr > th.sticky.sticky-top {
  top: 0;
}
</style>
